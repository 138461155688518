import './body.scss'

function Body () {
  return (
    <>
      <h1>Welcome</h1>
      <p>This page is currently under construction.</p>
    </>
  )
}

export default Body
