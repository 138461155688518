import Navbar from 'react-bootstrap/Navbar'
// import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'

function Header () {
  return (
    <Navbar collapseOnSelect expand="sm" bg='dark' variant='dark'>
      <Container>
        <Navbar.Brand href='#home'>
          {/* <img
            alt=''
            src='/logo.svg'
            width='30'
            height='30'
            className='d-inline-block align-top'
          /> */}{' '}
          marsoun.at
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        {/* <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#features">Gaming</Nav.Link>
            <Nav.Link href="#pricing">Quarantine-Zone</Nav.Link>
            <Nav.Link href="#about">About Me</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  )
}

export default Header
