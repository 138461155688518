// import logo from './logo.svg'
import './App.scss'
import Page from './components/page'

function App () {
  return (
    <Page />
  )
}

export default App
