import './page.scss'
import Header from './header/header'
import Body from './body/body'

function Page () {
  return (
    <>
      <Header />
      <Body />
    </>
  )
}

export default Page
